import CustomHead from 'blocks/CustomHead';
import storePageMessages from 'messages/storePages';
import { paths } from 'paths';
import React from 'react';
import { useIntl } from 'react-intl';
import { StaticProps, Services } from 'services/context';
import { getLocale } from 'services/locale';
import { PageProps } from 'types/page';
import retrieveFromContentfulCache from 'utils/buildCache/retrieveFromContentfulCache';
import retrieveFromLocaleCache from 'utils/buildCache/retrieveFromLocaleCache';
import retrieveFromStoresCache from 'utils/buildCache/retrieveFromStoresCache';
import StoreCountriesView from 'views/Stores';

function StoreCountriesListPage({ countries }) {
  const intl = useIntl();
  return (
    <>
      <CustomHead title={intl.formatMessage(storePageMessages.storesOverviewAllLocationsMetaTitle)} />
      <StoreCountriesView countries={countries} />
    </>
  );
}

type GetStaticProps = StaticProps<Services & { countries: { name: string; countryCode: string }[] }>;

export async function getStaticProps(props: PageProps): Promise<GetStaticProps> {
  const locale = getLocale(props.locale);

  if (!locale) {
    return {
      notFound: true
    };
  }

  const [pageLayoutData, webStore, stores, messages] = await Promise.all([
    retrieveFromContentfulCache('pageLayoutData', locale),
    retrieveFromContentfulCache('webStore', locale),
    retrieveFromStoresCache(locale.lang),
    retrieveFromLocaleCache(locale)
  ]);

  // Creates array of countries from the store data, by filtering by country code.
  const countries = stores.reduce((acc, val) => {
    if (!val.i18n_slug) {
      return acc;
    }
    const [country] = val.i18n_slug.split('/');

    acc.set(country, {
      amount: acc.has(country) ? acc.get(country).amount + 1 : 1,
      countryCode: val.country_code,
      countryUrl: `${paths.stores}/${country}`,
      name: val.country
    });

    return acc;
  }, new Map());

  return {
    props: {
      countries: Array.from(countries.values()),
      footer: pageLayoutData.footer,
      locale,
      menuNavigation: pageLayoutData.menu,
      messages: messages,
      pageName: 'storeCountryOverviewPage',
      webStore: webStore
    }
  };
}
export default StoreCountriesListPage;
